import { graphql } from "gatsby";
import React from "react";
import Image from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";

export const pageQuery = graphql`
  query($language: String!) {
    media_1: file(relativePath: { eq: "marketing/images-marketing-1.png" }) {
      ...fluidImage
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Marketing = ({ data }) => {
  const shortDescription =
    "En Estudio 94, podemos ayudarte a definir tu estrategia de marketing digital para que puedas formar y mantener una excelente presencia en redes.";
  const description = [
    "El marketing ha evolucionado mucho desde la época de los anuncios en periódicos y en televisión. En la era de las redes sociales, es importante que tu imagen digital se ajuste a tu audiencia, y que estés publicando contenido que interese y atraiga a más gente. Esto es especialmente relevante para marcas comerciales, para las cuales es esencial que haya cohesión entre páginas web y redes sociales.",
    "En Estudio 94, podemos ayudarte a definir tu estrategia de redes y SEO, produciendo recursos gráficos digitales para que puedas formar y mantener una excelente presencia en redes. ¡Ponte en contacto si te interesa un estudio personalizado!",
  ];

  return (
    <Layout pagename="marketing_services">
      <SEO title="Marketing digital" description={shortDescription} />
      <div className="page_content">
        <div className="service__media">
          <Image fluid={data.media_1.childImageSharp.fluid}></Image>
        </div>
        <div className="service__text">
          <h3 className="service__title">
            <Trans>Marketing digital</Trans>
          </h3>
          {description.map((paragraph) => (
            <p className="service__description">
              <Trans>{paragraph}</Trans>
            </p>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Marketing;
